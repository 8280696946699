import io from 'socket.io-client';
import urlcat from 'urlcat';
import { createChartDatafeedOfPolling } from '~/modules/SDK/chart4/createChartDatafeedOfPolling';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { socketOnSleepRecoverReconnect } from '~/modules/SDK/Socket3/socketOnSleepRecoverReconnect';
import { createStore } from '~/store/createStore';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { isUndefined } from 'lodash';
import { fr_datafeedStatus } from '~/pages/heineken_template/_fr/fr_datafeedStatus';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
// 這兩組物件，如果搬進去 factory 裡面的話，在「chart4」會發生「切換伺服器時，K棒不會跳動」的問題
const subTopics = {};
const historyLastKbarTime = {};
var SocketAction;
(function (SocketAction) {
    SocketAction["subscribeSymbol"] = "subscribe-symbol";
    SocketAction["unsubscribeSymbol"] = "unsubscribe-symbol";
})(SocketAction || (SocketAction = {}));
// TODO: 你可以先研究一下 現在 kbar 到底怎麼載入 怎麼 update 中間有出現斷線 或是 相關的邏輯，不希望 component 整個 re-render
// TODO: 大目標就是  確保各種情況  chart 都能正常  不要斷線/重載/漏K棒
/**
 * # @deprecated datafeed2 運作上線中，此 datafeed1 即將棄用
 *
 * 管理 socket 連線邏輯，並提供給 TVChart SDK
 *
 * @deprecated
 */
export const createDatafeedStore = (server, 
/**
 * # 因為是常數，未來直接內部用 agentStore 即可，不必再傳值
 *
 * @deprecated
 */
agent = fr_agents.agent, 
/**
 * # 因為是常數，未來直接內部用 agentStore 即可，不必再傳值
 *
 * @deprecated
 */
agentProduct = fr_agents.product) => createStore((set, get) => {
    const product = agentProduct.replace(/[\s\S]+?@/i, '');
    const $http = {
        get(url, params = {}) {
            return globalThis
                .fetch(urlcat(get().server.historyUrl + url, {
                ...params,
                agent,
                product,
            }))
                .then(res => res.json())
                .then(data => ({ data }));
        },
    };
    const socket = io(server.wsUrl || '', {
        query: {
            uid: fr_me.me.uid || useMeStore.getState().meUserState?.uid,
            agent,
            product,
        },
        autoConnect: false,
        reconnection: true, // 休眠/斷網後，前端應能自動重連。 TODO: 檢查 enable 後是否有其他可能的 bug
    });
    // logs
    socket.emit = new Proxy(socket.emit, {
        apply: (target, ev, args) => {
            log(`socket.emit(${args[0]})`, args[1], { subTopics });
            Reflect.apply(target, ev, args);
        },
    });
    const socketRef = {
        current: socket,
    };
    socket.on('connect', () => {
        log('socket.on(connect)', { subTopics });
        for (const key in subTopics) {
            const { symbol, resolution, lastBarTime, subscriberUID } = subTopics[key];
            socket.emit(SocketAction.subscribeSymbol, {
                symbol,
                resolution,
                lastBarTime,
                subscriberUID,
            });
        }
    });
    socket.on('disconnect', (event) => {
        log('socket.on(disconnect)', event);
    });
    socket.on('error', (error) => {
        log('socket.on(error)', error);
    });
    socket.on('data', (event) => {
        const { data, subscriberUID } = event;
        let newBars = JSON.parse(data);
        const sub = subTopics[subscriberUID];
        log('socket.on(data)', { newBars, subTopics });
        fr_datafeedStatus.analyzeKBars(newBars);
        if (!sub)
            return;
        if (newBars.length > 0) {
            // 若尚未有lastBar時間資訊(僅在第一次收到資料觸發)
            if (!sub.lastBarTime)
                sub.lastBarTime = historyLastKbarTime[subscriberUID] ?? newBars[0].time;
            // 濾掉時間比lastBar還早的K棒
            newBars = newBars.filter((b) => {
                if (isUndefined(sub.lastBarTime))
                    return false;
                return b['time'] >= sub.lastBarTime;
            });
            // 畫上(更新)K棒
            newBars.forEach((bar, index) => {
                sub.lastBarTime = bar.time;
                sub.listener(bar);
            });
        }
    });
    socketOnSleepRecoverReconnect(socketRef);
    return {
        server: {
            historyUrl: server.historyUrl,
            label: server.label,
            wsUrl: server.wsUrl,
        },
        socket: server.wsUrl ? socket : null,
        connect: () => {
            log(`datafeed.connect()`);
            socket.connect();
            socketRef.current = socket;
        },
        disconnect: () => {
            log(`datafeed.destroy()`);
            socketRef.current = null;
            socket.disconnect();
        },
        toDatafeed(updateFrequency = 5000) {
            if (!server.wsUrl) {
                return createChartDatafeedOfPolling(agent, get().server.historyUrl, updateFrequency);
            }
            return {
                onReady: callback => {
                    $http.get('/config', {}).then((response) => {
                        log(`chart.onReady()`, response.data);
                        return callback({
                            ...response.data,
                        });
                    });
                },
                searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
                    const params = {
                        limit: 30,
                        query: userInput,
                        exchange,
                        type: symbolType,
                    };
                    $http.get('/search', params).then((response) => {
                        onResultReadyCallback(response.data);
                    });
                },
                resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
                    $http
                        .get('/symbols', { symbol: symbolName })
                        .then(resp => {
                        const data = {
                            ...resp.data,
                        };
                        onSymbolResolvedCallback(data);
                    })
                        .catch(resp => {
                        onResolveErrorCallback(resp);
                    });
                },
                getBars: (symbolInfo, resolution, periodParams, onResult, onError) => {
                    const isFirstCall = periodParams.firstDataRequest;
                    const params = {
                        symbol: symbolInfo.ticker,
                        resolution,
                        from: periodParams.from,
                        to: periodParams.to,
                        countBack: periodParams.countBack,
                        ws: true,
                        metaData: true,
                    };
                    $http
                        .get('/history', params)
                        .then(async (resp) => {
                        const metaData = {
                            noData: resp.data.metaData.noData,
                            nextTime: resp.data.metaData.nextTime,
                        };
                        let historyKbars = resp.data.bars;
                        // 第一次呼叫若noData, retry一次
                        if (isFirstCall && metaData.noData) {
                            const retryResp = await $http.get('/history', params);
                            metaData.noData = retryResp.data.metaData.noData;
                            metaData.nextTime = retryResp.data.metaData.nextTime;
                            historyKbars = retryResp.data.bars;
                        }
                        // 記錄history回傳的最後一根K棒時間, 作為後續ws推播K棒參考
                        if (isFirstCall && historyKbars.length > 0) {
                            const subscriberUID = `${params.symbol}_#_${params.resolution}`;
                            historyLastKbarTime[subscriberUID] = historyKbars[historyKbars.length - 1].time;
                        }
                        onResult(historyKbars, metaData);
                        return true;
                    })
                        .catch((error) => {
                        let reason = error.message;
                        if (error.response) {
                            reason = error.response.data ? error.response.data.error.errCode : reason;
                        }
                        onError(reason);
                        return false;
                    });
                },
                subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
                    // 使休眠斷線後的 Chart 重新取得資料用（不確定有無真實效果）
                    // https://github.com/cory8249/charting_library/wiki/Chart-Methods#resetdata
                    // onResetCacheNeededCallback()
                    log(`chart.subscribeBars()`, { subTopics });
                    const sub = subTopics[subscriberUID];
                    if (sub)
                        return;
                    const symbol = symbolInfo.ticker;
                    socket.emit(SocketAction.subscribeSymbol, { symbol, resolution, subscriberUID });
                    if (!symbol) {
                        console.warn(`找不到 symbol`, { symbolInfo });
                    }
                    subTopics[subscriberUID] = {
                        symbol,
                        subscriberUID,
                        resolution,
                        symbolInfo,
                        lastBarTime: undefined,
                        listener: onRealtimeCallback,
                    };
                },
                unsubscribeBars(subscriberUID) {
                    const sub = subTopics[subscriberUID];
                    if (!sub)
                        return;
                    if (historyLastKbarTime[subscriberUID])
                        delete historyLastKbarTime[subscriberUID];
                    socket.emit(SocketAction.unsubscribeSymbol, { subscriberUID });
                    delete subTopics[subscriberUID];
                },
                getServerTime(callback) {
                    $http.get('/time').then(resp => {
                        callback(resp.data);
                    });
                },
            };
        },
    };
});
const log = debugAPI.datafeed.log.bind(debugAPI.datafeed);
